import { graphql, PageProps } from "gatsby";
import React from "react";
import { Layout } from "../../components/layout";
import { FormSection } from "../../components/rebrand/sections/Form/FormSection";
import { PageHeaderSection } from "../../components/rebrand/sections/PageHeader/PageHeaderSection";
import { FeaturedQuotesCard } from "../../components/rebrand/ui/FeaturedQuotesCard/FeaturedQuotesCard";
import { SEO } from "../../components/seo";
import { NewProjectPageData } from "../../data/newProject";

const { cardData, formSectionData } = NewProjectPageData;

export const pageQuery = graphql`
  query NewProject {
    site {
      siteMetadata {
        pages {
          newProject {
            title
            description
            keywords
          }
        }
      }
    }
  }
`;

interface NewProjectPageProps extends PageProps {
  data: Queries.NewProjectPageQuery;
}

export default function NewProjectPage(props: NewProjectPageProps) {
  const { location : { pathname }, data } = props;
  const { title, description } = data.site?.siteMetadata?.pages?.newProject ?? {} as Queries.SiteSiteMetadataPagesNewProject;
  const seo = {
      title: title as string,
      description: description as string,
      // keywords: keywords as [],
      pathname,
  };

  return (
    <Layout>
      <SEO {...seo}/>
      <PageHeaderSection title={title} subtitle={description} paddingBottomSpacing={[0, 0]} />
      <FormSection paddingTopSpacing={[7, 10]} {...formSectionData}>
        {/* <FeaturedQuotesCard {...cardData} /> */}
      </FormSection>
    </Layout>
  );
}
