import { FormSectionProps } from "../components/rebrand/sections/Form/FormSection";

const cardData = {
  /* grid props*/
  gridColumn: ["1 / span 12", "1 / span 6"],
  gridRow: "1",
  display: ["none", "block"],
};

const formSectionData: FormSectionProps = {
  formProps: {
    /* grid props*/
    gridColumn: ["1 / span 12", "1 / span 6"],
    gridRow: "1",

    /* content props*/
    title: "Cuéntanos que tienes en mente",
    type: "demo",
  },
};

export const NewProjectPageData = { cardData, formSectionData };
